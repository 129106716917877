<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="edit" class="mr-1" />{{ translate('edit_identity') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent class="full-height">
                    <b-list-group-item class="p-0 pt-2 pb-2">
                        <q-item class="p-0">
                            <q-item-section class="p-0">
                                <q-input dense square color="primary" type="text" id="title" v-model="identity.title" :label="translate('title')" />
                            </q-item-section>
                            <q-item-section side class="p-0 mt-2" top>
                                <q-btn :color="descriptionOpen ? 'primary' : 'secondary'" class="m-0 mr-3"  @click.stop="toggleVizibility('descriptionOpen')" dense flat round no-caps :icon="descriptionOpen ? 'comment' : 'comment'">
                                    <q-tooltip :offset="[10, 10]">{{ descriptionOpen ? translate('hide_description') : translate('show_description') }}</q-tooltip>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                        <q-input v-if="descriptionOpen" line="2" :label="translate('description')" v-model="identity.description" square dense color="primary" type="textarea" rows="2" />
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('email')" id="identityEmail" type="text" name="email" v-model="identity.email" />
                            </div>
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('phone')" id="identityPhone" type="text" name="phone" v-model="identity.phone" />
                            </div>
                        </div>
                        <div class="pl-3 pr-3">
                            <small class="text-uppercase">{{ translate('images') }}</small>
                            <b-form-file type="file" name="selectedFile" ref="selectedFile" multiple @change="onFileSelected" v-model="selectedFile" />
                        </div>
                    </b-list-group-item>
                    <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                        <q-item class="p-3">
                            <q-item-section side>
                                <q-btn type="submit" no-caps color="info" @click.prevent="registerIdentity">
                                    <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </div>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'EditIdentity',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            descriptionOpen: true,
            
            identity: {
                title: null,
                description: '',
                phone: null,
                email: null,
                filename: '',
            },
            selectedFile: null,
        }
    },
    created() {
        this.getIdentity()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        toggleVizibility: function (field) {
            this[field] = !this[field]
        },
        getIdentity: function () {
            axios.get(baseUrl + 'business')
                .then(response => {
                    if (response.data.status) {
                        this.identity = response.data.item
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        onFileSelected: function (event) {
            this.selectedFile = event.target.files[0]
            this.selectedFile.image_name = this.selectedFile.name.split(' ').join('_')
        },
        registerIdentity: function () {
            const formData = new FormData()
            formData.set('title', this.identity.title)
            formData.set('description', this.identity.description)
            formData.set('phone', this.identity.phone)
            formData.set('email', this.identity.email)
            if (this.selectedFile && this.selectedFile[0]) {
                formData.append(`file`, this.selectedFile[0])
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }

            axios.put(baseUrl + 'business', formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_identity_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            response: this.identity
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                    if (this.selectedFile !== null) {
                        this.identity.filename = this.selectedFile.name
                    }

                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })

                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
