<template>
<div class="fit">
    <b-card no-header no-body class="pt-3 pb-3">
        <b-row class="full-width">
            <b-col sm="12" md="4">
                <img class="full-width" :src="getProfilePhoto()" />
            </b-col>
            <b-col sm="12" md="8" class="pl-md-0">
                <q-item class="full-width pl-0 pr-0 mb-2">
                    <q-item-section>
                        <span class="text-muted">{{translate('profile_identity')}}</span>
                        <h4 class="mb-0">{{identity.title}}</h4>
                    </q-item-section>
                    <q-item-section v-if="$appSettings.currentUserType() !== 'customer'" side>
                        <q-btn v-if="!$q.screen.lt.sm" @click="showEditIdentityPanel" type="button" no-caps color="primary">
                            <i class="fas fa-edit mr-1"></i> {{translate('edit')}}
                        </q-btn>
                        <q-btn v-if="$q.screen.lt.sm" flat round no-caps color="primary" type="button" @click="showEditIdentityPanel">
                            <i class="fas fa-edit"></i>
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item class="full-width mt-2 pl-0 pr-0">
                    <q-item-section>
                        <span class="text-muted">{{translate('description')}}</span>
                        {{identity.description}}
                    </q-item-section>
                </q-item>
                <q-item class="full-width pl-0 pr-0">
                    <q-item-section>
                        <span class="text-muted">{{translate('email')}}</span>
                        {{identity.email}}
                    </q-item-section>
                </q-item>
                <q-item class="full-width pl-0 pr-0">
                    <q-item-section>
                        <span class="text-muted">{{translate('phone')}}</span>
                        {{identity.phone}}
                    </q-item-section>
                </q-item>
            </b-col>
        </b-row>
    </b-card>
</div>
</template>

<script>
import EditIdentity from './forms/EditIdentity'
export default {
    name: 'Identity',
    data: function () {
        return {
            identity: {},
            msg: null,
            enableButon: false,
        }
    },
    computed: {
        //Disable or enable edit button by user role
        typeDisable: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableButon
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableButon
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableButon
            }
        }
    },
    created() {
        this.getIdentity()
    },
    methods: {
        getProfilePhoto: function () {
            return baseUrl + "images/general/image-name/" + this.identity.filename
        },

        //Get the identity information with axios
        getIdentity: function () {
            axios.get(baseUrl + 'business')
                .then(response => {
                    if (response.data.status) {
                        this.identity = response.data.item
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },

        //SlideOut panel with @click method, and get the response from identity
        showEditIdentityPanel: function () {
            const panelInstance = this.$showPanel({
                component: EditIdentity,
            })

            panelInstance.promise
                .then(result => {
                    return this.getIdentity()
                })
        },
    }
}
</script>

<style scoped>
.fade-enter-active {
    transition: all .3s ease
}

.fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)
}

.fade-enter,
.fade-leave-to {
    transform: translateX(10px);
    opacity: 0
}
</style>
